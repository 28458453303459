//@ts-nocheck
import React, {ReactNode} from "react";
import styled from "@emotion/styled";

const Title = styled.div`
  font-family: Red Hat Display;
  font-size: 32px;
  font-weight: 800;
  line-height: 35px;
  letter-spacing: -0.02em;
  text-align: center;
  color: ${({ theme }) => theme.colors.titles}
`

interface Props {
    children: ReactNode
}
export default ({ children, ...props }: Props) => {
    return (
        <Title {...props}>
            {children}
        </Title>
    )
}