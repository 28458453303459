//@ts-nocheck
import React, {ReactNode} from "react";
import styled from "@emotion/styled";

const Intro = styled.div`
  margin-top: 16px;
  font-family: Red Hat Text;
  font-size: 18px;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
  color: ${({ theme }) => theme.colors.intro}
`

interface Props {
    children: ReactNode
}
export default ({ children, ...props }: Props) => {
    return (
        <Intro {...props}>
            {children}
        </Intro>
    )
}