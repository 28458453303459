//@ts-nocheck
import React, {ReactNode} from "react"
import styled from "@emotion/styled";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  width: fit-content;
  background: ${({ theme }) => theme.colors.infoBackground};
  font-family: 'Red Hat Display';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 110%;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.infoFontColor};
  border-radius: 16px;
`

interface Props {
    children: ReactNode
}

export default ({ children }: Props) => {
    return (
        <Container>
            {children}
        </Container>
    )
}