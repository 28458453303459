import React from 'react'
import AppLayout from "../layout/AppLayout";
import styled from "@emotion/styled";
import ChargePointSelector from "../components/chargepoints/ChargePointSelector";
import Title from "../components/typography/Title";
import Intro from "../components/typography/Intro";
import {useReactiveVar} from "@apollo/client";
import {customerId, customerName} from "../CustomerThemeProvider";
import {CircleLoader} from "react-spinners";

const StyledChargePointSelector = styled(ChargePointSelector)`
  margin-top: 24px;
`

export default () => {
    const customer = useReactiveVar(customerName)
    const id = useReactiveVar(customerId)

    return (
        <AppLayout>
            {!id && <CircleLoader />}
            {id &&
            <>
                <Title>Welkom bij {customer}</Title>
                <Intro>
                    Plug je auto in en klik hieronder op de laadpaal waar je auto mee is verbonden. Dit kan tot
                    vijf minuten na de start van het laden.
                </Intro>
                <StyledChargePointSelector />
            </>
            }
        </AppLayout>
    )

}