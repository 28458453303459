//@ts-nocheck
import React, {ReactNode} from 'react'
import styled from "@emotion/styled";
import TopBar from "../components/layout/TopBar/TopBar";

const Body = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.background};
  background-image: ${({ theme }) => `url("${theme.backgroundImage}")`};
`

const MainContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 30px 24px 100px;
  overflow-y: auto;
`

interface Props {
    children: ReactNode
}
export default ({children}: Props) => {

    return (
        <Body>
            <div className="container mx-auto max-w-3xl relative h-full">
                <TopBar />
                <MainContent>
                    {children}
                </MainContent>
            </div>
        </Body>
    )
}