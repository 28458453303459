import React, {ReactNode, useEffect, useState} from "react"
import theme from "./theme";
import {ThemeProvider} from "@emotion/react";
import {gql, makeVar, useQuery} from "@apollo/client";
import {CircleLoader} from "react-spinners";

const CUSTOMER_QUERY = gql`
    query GetCustomerForDomain($domain: String) {
      getCustomerForDomain(domain: $domain) {
        branding {
          backgroundColor
          backgroundImage
          buttonActiveBackgroundColor
          buttonBackgroundColor
          buttonFontColor
          buttonActiveFontColor
          chartMetricColor
          chartTotalColor
          infoBackgroundColor
          infoFontColor
          introColor
          logoUrl
          metricFontColor
          titleColor
          topbarBackgroundColor
          timeSelectorButtonColor
        }
        id
        name
      }
    }
`

interface Props {
    children: ReactNode
}

export const customerName = makeVar("")
export const customerId = makeVar<null | string>(null)

export default ({ children }: Props) => {
    const location = window.location
    const [ selectedTheme, setTheme ] = useState(theme)
    const { data, loading } = useQuery(CUSTOMER_QUERY, {
        variables: {
            domain: location?.hostname
        }
    })

    useEffect(() => {
        if(data?.getCustomerForDomain?.branding) {
            const { branding } = data.getCustomerForDomain
            const newTheme = {
                ...theme,
                background: branding.backgroundColor,
                backgroundImage: branding.backgroundImage,
                logoUrl: branding.logoUrl,
                buttons: {
                    ...theme.buttons,
                    backgroundColor: branding.buttonBackgroundColor,
                    activeBackgroundColor: branding.buttonActiveBackgroundColor,
                    fontColor: branding.buttonFontColor,
                    activeFontColor: branding.buttonActiveFontColor
                },
                charts: {
                    ...theme.charts,
                    metricColor: branding.chartMetricColor,
                    totalColor: branding.chartTotalColor
                },
                colors: {
                    ...theme.colors,
                    background: branding.backgroundColor,
                    infoBackground: branding.infoBackgroundColor,
                    infoFontColor: branding.infoFontColor,
                    intro: branding.introColor,
                    metricFontColor: branding.metricFontColor,
                    titles: branding.titleColor,
                    timeSelectorButtonColor: branding.timeSelectorButtonColor
                },
                topBar: {
                    ...theme.topBar,
                    backgroundColor: branding.topbarBackgroundColor
                }
            }
            setTheme(newTheme)
        }

        if(data?.getCustomerForDomain?.id) {
            customerId(data?.getCustomerForDomain?.id)
        }

        if(data?.getCustomerForDomain?.name) {
            customerName(data.getCustomerForDomain.name)
        }
    }, [data])

    return (
        <>
            {loading && <CircleLoader />}
            {!loading &&
                <ThemeProvider theme={selectedTheme}>
                    {children}
                </ThemeProvider>
            }
        </>
    )
}