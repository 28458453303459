//@ts-nocheck
import React from "react";
import styled from "@emotion/styled";
import {useTheme} from "@emotion/react";

const Container = styled.div`
  background-color: ${({ theme }) => theme.topBar.backgroundColor};
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 32px rgba(0, 0, 100, 0.08);
`

const Logo = styled.img`
  height: 37px;
`

export default () => {
    const theme = useTheme()
    return (
        <Container>
            <Logo src={theme.logoUrl} />
        </Container>
    )
}