import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import Router from "./router";
import CustomerThemeProvider from "./CustomerThemeProvider";
import {ApolloClient, ApolloProvider, InMemoryCache} from "@apollo/client";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

//@todo: move to own component and add local storage persist
const cache = new InMemoryCache({
    typePolicies: {
        Query: {
            fields: {}
        }
    }
})

const apiClient = new ApolloClient({
    uri: 'https://charging-api.loqio.app/graphql',
    cache: cache,
})

root.render(
  <React.StrictMode>
      <ApolloProvider client={apiClient}>
          <CustomerThemeProvider>
            <Router />
          </CustomerThemeProvider>
      </ApolloProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
