//@ts-nocheck
import React, {ButtonHTMLAttributes} from 'react'
import styled from "@emotion/styled";

const Button = styled.button<{
    active: boolean,
    position: BUTTON_POSITION
}>`
  width: 100%;
  background-color: ${({ active, theme }) => active ? theme.buttons.activeBackgroundColor: theme.buttons.backgroundColor};
  border-top-left-radius: ${
    ({ position }) => position === BUTTON_POSITION.left || position === BUTTON_POSITION.irrelevant ? 
            "3px" : 0
    };
  border-bottom-left-radius: ${
    ({ position }) => position === BUTTON_POSITION.left || position === BUTTON_POSITION.irrelevant ?
        "3px" : 0
    };
  border-top-right-radius: ${
    ({ position }) => position === BUTTON_POSITION.right || position === BUTTON_POSITION.irrelevant ?
        "3px" : 0
    };
  border-bottom-right-radius: ${
    ({ position }) => position === BUTTON_POSITION.right || position === BUTTON_POSITION.irrelevant ?
        "3px" : 0
    };
  height: 55px;
  padding: 12px 24px;
  font-family: Red Hat Display;
  font-size: 18px;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: center;
  color: ${({ theme, active }) => active? theme.buttons.activeFontColor : theme.buttons.fontColor};
`

export enum BUTTON_POSITION {
    left,
    middle,
    right,
    irrelevant
}

interface Props extends ButtonHTMLAttributes<any> {
    active?: boolean
    position?: BUTTON_POSITION
    onClick: () => void
    text: string
}

export default ({ active = false, position = BUTTON_POSITION.irrelevant, onClick, text, ...props }: Props) => {
    return (
        <Button onClick={onClick} active={active} position={position} {...props}>
            {text}
        </Button>
    )
}