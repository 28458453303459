import React from 'react'
import {BrowserRouter, Route, Routes} from "react-router-dom";
import ChargeStationSelectionScreen from "./screens/ChargeStationSelectionScreen";
import SocketSelectionScreen from "./screens/SocketSelectionScreen";
import ChargingScreen from "./screens/ChargingScreen";
export default () => {

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<ChargeStationSelectionScreen />} />
                <Route path=":stationId/sockets" element={<SocketSelectionScreen />} />
                <Route path=":stationId/charging/:socketId" element={<ChargingScreen />} />
            </Routes>
        </BrowserRouter>
    )
}