

const theme = {
    colors: {
        blueDark: '#0D70EE',
        blueEnergetic: '#008CFF',
        blueEnergeticLight: '#96ccfd',
        coldGrey: '#969BBE',
        warm: '#F0F0FA',
        background: '#F0F0FA',
        menuBackground: '#0D70EE',
        subMenuBackground: '#008CFF',
        titles: '#008CFF',
        measurement: '#969BBE',
        active: '#008CFF',
        inactive: '#969BBE',
        menuItem: '#FFFFFF',
        border: 'rgba(150, 155, 190, 0.2)',
        alert: "#FFA100FF",
        danger: "#F840A0",
        chartTitle: "#000000",
        chartBackground: "#FFFFFF",
        metricLabel: "#000000",
        timeFrameSelectionMenuBg: "rgba(150, 155, 190, 0.2)",
        infoBackground: "rgba(150, 155, 190, 0.2)",
        metricFontColor: "#969BBE",
        intro: "#000000",
        timeSelectorButtonColor: "#008CFF"
    },
    background: '#F0F0FA',
    logoUrl: "https://charging.loqio.app/media/logo.png",
    buttons: {
        backgroundColor: "#969BBE",
        activeBackgroundColor: "#008CFF",
        fontColor: "#FFFFFF",
        activeFontColor: "#FFFFFF"
    },
    charts: {
        metricColor: "#008CFF",
        totalColor: "#96ccfd"
    },
    topBar: {
        backgroundColor: "#FFFFFF"
    }
}

export default theme