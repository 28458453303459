//@ts-nocheck
import React, {ReactNode, useEffect, useRef, useState} from 'react'
import styled from "@emotion/styled";
import {ResponsivePie} from "@nivo/pie";
import {useTheme} from "@emotion/react";

interface Props {
    capacity: number
    power: number
    name: string | ReactNode
    onClick?: () => void
    unit?: string,
    divideKW?: boolean
}

const PieContainer = styled.div`
  container: piecontainer / inline-size;
  width: 256px;
  height: 256px;
  border-radius: 128px;
  background-color: ${({ theme }) => theme.colors.chartBackground};
  box-shadow: 0 0 32px 0 rgba(0, 0, 100, 0.08);
  display: flex;
  
  .metric-text {
    font-size: 38px;
    font-weight: 900;
    font-family: 'Red Hat Display';
    fill: ${({ theme }) => theme.colors.metricFontColor};
  }
  
  .metric-label {
    font-size: 16px;
    font-weight: 700;
    font-family: 'Red Hat Display';
    fill: ${({ theme }) => theme.colors.metricFontColor};
  }
  
  @container piecontainer (width < 256px) {
    .metric-label {
      font-size: 14px;
      transform: translate(0, 16px);
    }
    .metric-text {
      font-size: 24px;
    }
  }
`
export default ({ capacity, power, name, onClick, unit, divideKW = true, ...props }: Props) => {
    const ref = useRef<HTMLDivElement>(null)
    const [ topMargin, setTopMargin ] = useState(0)
    const theme = useTheme()

    const data = [
        {
            id: 'power',
            value: Math.abs(power),
            negativeValue: power < 0,
            color: theme.charts.metricColor,
            label: name,
            unit,
            divideKW
        },
        {
            id: 'capacity',
            value: capacity - Math.abs(power),
            color: theme.charts.totalColor
        }
    ]

    useEffect(() => {
        //When the start and end angle are greater than 0 the chart does not align to the top of the container
        //applying a margin fixes that
        if(ref.current) {
            const rect = ref.current.getBoundingClientRect()
            const top = 0 - (rect.height * 0.175)
            setTopMargin(top)
        }
    }, [ref?.current])

    return (
        <PieContainer {...props} ref={ref} onClick={onClick}>
            <ResponsivePie
                data={data}
                startAngle={-130}
                endAngle={130}
                margin={{
                    top: topMargin
                }}
                flex={true}
                innerRadius={0.85}
                enableArcLabels={false}
                enableArcLinkLabels={false}
                isInteractive={false}
                colors={{ datum: 'data.color' }}
                layers={['arcs', CenteredMetric, CenteredLabel]}
            />
        </PieContainer>
    )
}

const CenteredMetric = ({ dataWithArc, centerX, centerY }) => {
    const value = dataWithArc?.[0]?.data?.negativeValue ?  (0 - dataWithArc?.[0]?.value) : dataWithArc?.[0]?.value || 0
    const unit = dataWithArc?.[0]?.data?.unit ? dataWithArc?.[0].data.unit : getUnit()
    const divideKW = dataWithArc?.[0]?.data?.divideKW

    let metric = ''

    function getUnit() {
        if(value >= 1000 || value <= -1000) {
            return 'kW'
        } else {
            return 'W'
        }
    }

    if((divideKW && value >= 1000) || (divideKW && value <= -1000 )) {
        const number = Math.round(value / 10) / 100
        metric = `${Intl.NumberFormat().format(number)} ${unit}`
    } else {
        metric = `${value} ${unit}`
    }

    return (
        <text
            x={centerX}
            y={centerY + 12}
            textAnchor="middle"
            dominantBaseline="central"
            className="metric-text"
        >
            {metric}
        </text>
    )
}

const CenteredLabel = ({ dataWithArc, centerX, centerY }) => {
    const label = dataWithArc?.[0].label || ''
    return (
        <text
            x={centerX}
            y={centerY - 36}
            textAnchor="middle"
            dominantBaseline="central"
            className="metric-label"
        >
            {label}
        </text>
    )
}