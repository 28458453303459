import React, {useEffect} from 'react'
import AppLayout from "../layout/AppLayout";
import Title from "../components/typography/Title";
import Intro from "../components/typography/Intro";
import PrimaryButton from "../components/buttons/PrimaryButton";
import {useNavigate, useParams} from "react-router-dom";
import {gql, useQuery, useReactiveVar} from "@apollo/client";
import {customerName} from "../CustomerThemeProvider";
import {CircleLoader} from "react-spinners";
import styled from "@emotion/styled";

const SocketButton = styled(PrimaryButton)`
  margin-left: 16px;
  :first-child {
    margin: 0;
  }
`

const STATION_QUERY = gql`
    query GetChargingStation($chargingStationId: ID!) {
      getChargingStation(chargingStationId: $chargingStationId) {
        id
        sockets {
          id
          isClaimable
          label
        }
      }
    }
`

export default () => {
    const navigate = useNavigate()
    const customer = useReactiveVar(customerName)
    const { stationId } = useParams()
    const { data, loading } = useQuery(STATION_QUERY, {
        variables: {
            chargingStationId: stationId
        },
        fetchPolicy: "no-cache",
        pollInterval: 5000
    })

    useEffect(() => {
        if(data?.getChargingStation && data?.getChargingStation?.sockets?.length === 1) {
            const socket = data?.getChargingStation?.sockets[0]
            navigate(`/${station.id}/charging/${socket.id}`)
        }
    }, [data])

    const station = data?.getChargingStation || {}

    return (
        <AppLayout>
            <Title>Welkom bij {customer}</Title>
            <Intro>
                Met welke uitgang heb je jouw auto verbonden?
            </Intro>

            {loading && <CircleLoader />}
            {!loading && station?.sockets &&
            <div className="flex flex-row w-full justify-between mt-8">
                {/*// @ts-ignore*/}
                {station.sockets.map(socket => (
                        <SocketButton
                            active={socket.isClaimable}
                            disabled={!socket.isClaimable}
                            onClick={() => {
                                navigate(`/${station.id}/charging/${socket.id}`)
                            }}
                            text={socket.label} />
                    ))
                }
            </div>
            }
        </AppLayout>
    )
}